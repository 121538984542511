import React, { PureComponent } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

export class BeforeRulesRegulations extends PureComponent {
  render() {
    return (
      <>
        <HeaderAfterLogin />

        <main className="main mt-4">
          <div className="container rules-regulations">
            <h2>Gaming Condition</h2>
            <h5 className="sub-head">
              DADDY11 Prop Gamers offers all-in-spread pricing without any
              additional commission per lot fee.
            </h5>
            <h5 className="sub-head">
              See full Prop Gamers Challenge Rules and Gaming Conditions here.
              {/* <a
                href="/rules_for_trading_challenges.pdf"
                download="Rules for Trading Challenges"
              >
                here
              </a> */}
            </h5>

            <div className="content-box">
              These Challenge Terms and Conditions are effective from Sept-24
              and govern rights and obligations in connection with the use of
              services (the “Services”) provided by DADDY11 Labs Assessment
              Limited, company registration number 15957271 with its (“DADDY11”,
              “we”, “our” or the “Company”), under these Terms, offered through
              the https://propgamer.DADDY11.com. You are under no obligation to
              use the Services if you do not agree or understand any portion of
              these Terms, nor should you use the Services unless you understand
              and agree to these Terms. Your use of the Services constitutes
              your agreement to comply with these Terms.
            </div>

            <h3>1. INTRODUCTION</h3>
            <p>
              1.1. These Terms govern your and DADDY11 (“you”, “your”, or the
              “User”) rights and obligations in connection with the use of
              Services provided by DADDY11.
            </p>
            <p>
              1.2. By completing the registration form available on the Website
              (“Registration”) you create an account (“Account”) and accept the
              Terms. You may be required to accept additional terms and
              conditions for the Services of your choice offered by DADDY11 or
              other services offered by any entity that directly or indirectly
              through one or more intermediaries’ controls or is controlled by
              or is under common control with the Company hereto (“Affiliate”).
            </p>
            <p>
              1.3. The Services consist of the provision of the Account and
              enabling access to a demo gaming account that allows you to
              evaluate your gaming proficiency by participating in the demo
              gaming challenges (“Challenge”). The demo gaming accounts
              infrastructure is maintained by DADDY11 Global Markets Limited and
              the Company does not hold any license to provide regulated
              financial services. DADDY11 does not engage in the direct
              provision of live and demo gaming services or live or demo
              accounts. DADDY11 Services are intended solely for educational and
              assessment purposes, and the completion of the Challenge or fee
              payment in no way implies or guarantees a professional.
            </p>
            <p>
              1.4 DADDY11 does not provide Services to the User that is under 18
              (eighteen) years of age; has the residence outside the territory
              of the countries recognized as Allowed Jurisdictions; subject to
              the relevant international or local sanctions, has a criminal
              record related to financial crime or terrorism.
            </p>
            <p>
              By accepting these Terms you also acknowledge that (i) the funds
              provided to you for demo gaming via the Challenge are fictitious
              and (ii) that you have no right to possess those fictitious funds
              beyond the scope of their use within the Services, and (iii) that
              these funds may not be used for any actual gaming and (iv) that
              you are not entitled to the withdrawal of those funds, and that
              (v) you will not be paid any remuneration or profits based on the
              results of your simulated gaming, nor will you be required to pay
              any losses.
            </p>
            <p>
              DADDY11 does not bear any responsibility for gaming or other
              investment activities performed by the User outside the
              relationship with us, for example by using data or other
              information from the Challenge, or otherwise related to the
              Services in real gaming on sports markets.
            </p>

            <h3>2. REGISTRATION</h3>
            <p>
              2.1. You can order the Services through the Website by completing
              the Registration and accepting the Terms. You need to provide us
              with certain information about yourself before we make the
              Services available for you. We refer to this information as
              "Registration Data". Access to the Services will be conditioned on
              the provision of Registration Data.
            </p>
            <p>
              2.2. We may carry out checks (including but not limited to
              verification of identity, fraud prevention checks and checks into
              your current and past activities on the Account and the Challenge)
              from time to time as we deem appropriate. Your Registration Data
              or other information may be used in prevention of cybercrime,
              fraud or money laundering as well as for the management of your
              Account. Additionally, DADDY11 reserves the right to verify the
              information provided pursuant to Clause 1.5. with any sources
              available to the Company, including through third-party
              verification service providers.
            </p>
            <p>
              2.3. All data that you provide in connection with the Services or
              otherwise must be complete, true, and up to date. You must
              immediately notify us of any change in your data or update the
              data in your Account. DADDY11 provides Services at a monetary
              cost. Fees and availability are subject to change. The change does
              not affect the Services purchased before the change. It is your
              responsibility to thoroughly read and understand any such terms
              and conditions.
            </p>
            <p>
              2.4. We may refuse to open the Account for you for any reason.
            </p>

            <p>The User should meet the following minimum requirements:</p>
            <p>1. access to the Internet,</p>
            <p>2. possession of an email account</p>
            <p>3. up-to-date version of an Internet browser</p>

            <h3>3. FEES AND PAYMENT</h3>
            <p>
              3.1. Details of the Challenge terms and all fees applicable to the
              Services can be found on DADDY11 Website.
            </p>
            <p>
              3.2. The Challenge fee paid by the User is made for the purpose of
              assessing the User’s knowledge and skills via the Challenge
              offered by DADDY11.
            </p>
            <p>
              3.3. The amounts of fees for the Services are in INR. You can pay
              the fee for the selected option of the Challenge by a payment card
              or using other means of payment that are offered and operated by
              an external payment provider.
            </p>
            <p>
              3.4. The fee is considered paid when its full amount is credited
              to the DADDY11 account. User bears all fees charged to User by the
              payment service provider (according to the valid price list of the
              payment service provider) in connection with the transaction and
              the User is obliged to ensure that the respective fee for the
              selected Service is paid in full.
            </p>

            <h4>What is a Funded Betting Account?</h4>
            <p>
              A <b>funded betting account</b> refers to a type of account where
              a player is given points to bet by a funding provided, say, a
              sports bettor would be given access to a bankroll from a
              sponsoring entity (like a betting firm) to place bets, and in
              return, they would share a portion of their winnings with the
              firm.
            </p>
            <p>
              <b>How to Register & Login?</b>
            </p>
            <p>
              Visit <a href="www.daddy11.com">www.daddy11.com</a> and on the top
              right screen you will see an option of registering your account
              with your Mobile number. You will receive an OTP and you will be
              successfully logged in.
            </p>
            <p>
              On the Homepage, you will see options of Master 1, Master 2,
              Master 3 and following...Each challenge has its own ID, you can
              deposit the fees and enter a challenge.
            </p>

            <p>
              <b>How to play?</b>
            </p>
            <ul className="dots">
              <li>
                <b>Evaluation/Challenge Phase</b>: You typically need to pass a
                challenge or evaluation in which you have to double the amount
                that has been given to you with keeping all rules and
                regulations in place.
              </li>
              <li>
                <b>Timeframe</b>: Each challenge is valid for 10 days. A player
                must bet for 5 consecutive days to continue to be a part of the
                challenge.
              </li>
            </ul>

            <h5>Profit Sharing:</h5>
            <p>
              If the player wins, the profits would be split between the player
              and the backer. For example, the profit slab is 20%. For Example-
              the player wins ₹10,000, the win will be ₹2,000.
            </p>
            <p>
              If the player fails to double the amount within 10 days of
              validity of the challenge, they don’t win anything.
            </p>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Challenges</th>
                    <th>Challenge Fees (₹)</th>
                    <th>Points in A/c</th>
                    <th>Profit Gain (2x)</th>
                    <th>Winnings Amount (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Challenge 1</td>
                    <td>500</td>
                    <td>10K</td>
                    <td>20K</td>
                    <td>
                      <b>2K</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Challenge 2</td>
                    <td>5000</td>
                    <td>1Lac</td>
                    <td>2Lac</td>
                    <td>
                      <b>20K</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Challenge 3</td>
                    <td>10000</td>
                    <td>3Lac</td>
                    <td>6Lac</td>
                    <td>
                      <b>60K</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Challenge 4</td>
                    <td>25000</td>
                    <td>10Lac</td>
                    <td>20Lac</td>
                    <td>
                      <b>2Lac</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Challenge 5</td>
                    <td>50000</td>
                    <td>25Lac</td>
                    <td>50Lac</td>
                    <td>
                      <b>5Lac</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Challenge 6</td>
                    <td>100000</td>
                    <td>50Lac</td>
                    <td>1Crore</td>
                    <td>
                      <b>10Lac</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h3>4. CHALLENGE</h3>
            <p>
              4.1. After completing the Registration and paying the fee for the
              Challenge in line with Section 3, the User will receive access to
              the selected Challenge. Challenge access credentials and login
              instructions will be provided to the User via email.
            </p>
            <p>
              4.2. The Challenge is activated when it is purchased. You
              acknowledge and accept that the rules and objectives of the
              particular Challenge will be made available to you on the Website
              prior to purchasing the Challenge. Financial limitations and
              additional restrictions on how many Challenges User may take apply
              in accordance with the separate provisions set forth on the
              Website.
            </p>
            <p>
              4.3. To maintain access to the Challenge, Users are obliged to
              actively participate by executing at least one games within every
              consecutive 20-day period. Failure to execute a games within this
              timeframe constitutes a breach of this Agreement ("Inactivity").
              To reset the 20-day Inactivity period and maintain access, Users
              should execute a games within the Challenge. Failure to rectify
              the Inactivity within the specified 20-day period will result in
              the user losing access to the Challenge.
            </p>
            <p>
              4.4. During the Challenge, you may execute any simulated
              transactions, unless these constitute forbidden gaming strategies
              or practices within the meaning of the Terms. Restrictions may
              also be imposed by the terms and gaming conditions of the
              Challenge.
            </p>
            <p>
              4.5. You acknowledge that the Company has access to information
              about the demo games that you enter into during the Challenge and
              your Challenge activity. You grant your permission for this
              information to be shared with and between DADDY11 Affiliates,
              DADDY11 service providers or entities who are otherwise affiliated
              with the Company, and you grant the Company and these
              persons/entities your consent and authorization to handle this
              information to the extent that may be necessary for the
              performance of this Agreement or justified by the legitimate
              interest of DADDY11. You agree that these activities may be
              performed automatically without any further consent, consultation,
              or approval on your part being necessary, and that you are not
              entitled to any remuneration or revenue associated with the use of
              the data by the Company.
            </p>
            <p>
              4.6. The User accepts and acknowledges that the market information
              displayed during the Challenge may be delayed or inaccurate.
            </p>

            <h4>Rules and Regulations :</h4>
            <p>1. The challenge is valid for a period of 10 days.</p>
            <p>
              2. Participants must strictly follow the prescribed minimum 5% and
              maximum 10% bet amounts for each challenge.{" "}
              <b>Example- You have 10000 available for betting or investing.</b>
            </p>
            <p>
              <b>
                Minimum Bet (5%): The minimum bet you can place is 5% of 10000,
                which is 500.
              </b>
            </p>
            <p>
              <b>
                Maximum Bet (10%): The maximum bet you can place is 10% of
                10000, which is 1000.
              </b>
            </p>
            <p>
              3. Each challenge has a 20% loss limit. For example, if your
              account balance starts at ₹10,000 and drops to ₹8,000, you will be
              disqualified from the challenge, and the remaining amount will be
              deemed null and void.
            </p>
            <p>
              4. The maximum bet per match (10% of Funded Account): the maximum
              amount you can bet on a single match is limited to 10% of the
              total amount available in your funded account.
            </p>
            <p>
              5. Minimum Odds Rule(40 Paisa): Another rule states that you
              cannot place a bet if the odds for that particular match are less
              than 40 paisa
            </p>
            <p>
              6. A 20% profit-sharing rate applies to each challenge or account.
            </p>
            <p>
              7. If you place any bet which falls under 40 paisa will not be
              counted.
            </p>

            <h4>Violations:</h4>
            <p>1. Non-Compliance Consequences:</p>
            <ul className="circle">
              <li>
                Violation of any rules or exceeding established limits will
                result in account suspension.The company has full authority to
                enforce this action.
              </li>
            </ul>

            <p>2. Fraudulent Activity:</p>
            <ul className="circle">
              <li>
                The company reserves the right to terminate any account
                suspected of engaging in fraudulent activity
              </li>
              <li>
                If a player is suspected to have 2 accounts and has been noticed
                to do something fraudulent, the company holds the right to
                terminate his account any time.
              </li>
              <li>
                If you incur a loss exceeding 20% of your balance for 3
                consecutive days, you will be removed from the challenge.
              </li>
            </ul>

            <p>
              <b>Handling Exceeding the 10% Maximum Bet Limit:</b>
            </p>
            <p>
              <b>
                First Offense - Warning : Ensure strict adherence to the 10%
                maximum bet limit to avoid encountering warnings or account
                suspension.
              </b>
            </p>
            <p>
              <b>Second Offenses - Account Suspension:</b>
            </p>
            <p>
              <b>Failing to Meet Betting Requirements:</b>
            </p>
            <p>
              <b>
                Disqualification: Failing the challenge due to non-compliance
                with regulations may lead to disqualification from the current
                challenge or competition.
              </b>
            </p>

            <h3>5. General Terms and Conditions</h3>
            <p>5.1. The challenge is valid for a period of 10 days.</p>
            <p>
              5.2. Participants must strictly follow the prescribed minimum and
              maximum bet amounts for each challenge. The company assumes no
              responsibility for violations of these limits.
            </p>
            <p>
              5.3. Each challenge has a 20% loss limit. For example, if your
              account balance starts at ₹10,000 and drops to ₹8,000, you will be
              disqualified from the challenge, and the remaining amount will be
              deemed null and void.
            </p>
            <p>
              5.4. The maximum bet per match is capped at ₹1,000. Exceeding this
              limit will result in disqualification from the challenge.
            </p>
            <p>
              5.5. If you incur a loss exceeding 20% of your balance for 3
              consecutive days, you will be removed from the challenge.
            </p>
            <p>
              5.6. A 20% profit-sharing rate applies to each challenge or
              account.
            </p>
            <p>
              5.7. Non-compliance with any rules or exceeding the established
              limits will lead to account suspension. The company reserves the
              right to enforce this action.
            </p>
            <p>
              5.8. The company reserves the right to terminate any account
              suspected of fraudulent activity.
            </p>

            <div className="countries-list">
              <h3>
                Annex A <br /> Allowed Jurisdictions
              </h3>
              <div className="list">
                <ul>
                  <li>Algeria</li>
                  <li>Angola</li>
                  <li>Antarctica</li>
                  <li>Antigua and Barbuda</li>
                  <li>Argentina</li>
                  <li>Armenia</li>
                  <li>Aruba</li>
                  <li>Azerbaijan</li>
                  {/* <li>Afghanistan</li>
                  <li>Albania</li>
                  <li>Andorra</li>
                  <li>Australia</li>
                  <li>Austria</li> */}

                  <li>Bahamas</li>
                  <li>Bahrain</li>
                  <li>Bangladesh</li>
                  <li>Belize</li>
                  <li>Benin</li>
                  <li>Bermuda</li>
                  <li>Bhutan</li>
                  <li>Bolivia</li>
                  <li>Bosnia and Herzegovina</li>
                  <li>Botswana</li>
                  <li>Bauvet Islands</li>
                  <li>Brazil</li>
                  <li>Brunei</li>
                  <li>Burundi</li>
                  {/* <li>Barbados</li>
                  <li>Belarus</li>
                  <li>Belgium</li>
                  <li>Brunei</li>
                  <li>Bulgaria</li>
                  <li>Burkina Faso</li> */}

                  <li>Cape Verd / eCabo Verde</li>
                  <li>Central African Republic</li>
                  <li>Chad</li>
                  <li>Chile</li>
                  <li>Cocos (Keeling) Island</li>
                  <li>Colombia</li>
                  <li>Comoros</li>
                  <li>CooK Island</li>
                  <li>Costa Rica</li>
                  <li>Cote D'ivoire</li>
                  <li>Curacao</li>
                  {/* <li>Cambodia</li>
                  <li>Cameroon</li>
                  <li>Canada</li>
                  <li>China</li>
                  <li>Congo, Democratic Republic of the</li>
                  <li>Congo, Republic of the</li>
                  <li>Croatia</li>
                  <li>Cuba</li>
                  <li>Cyprus</li>
                  <li>Czech Republic</li> */}

                  <li>Djibouti</li>
                  <li>Dominica</li>
                  <li>Dominican Republic</li>
                  {/* <li>Denmark</li> */}

                  <li>Ecuador</li>
                  <li>Egypt</li>
                  <li>El Salvador</li>
                  <li>Equatorial Guinea</li>
                  <li>Eritrea</li>
                  <li>Ethiopia</li>
                  {/* <li>East Timor (Timor-Leste)</li>
                  <li>Estonia</li>
                  <li>Eswatini</li> */}

                  <li>Fiji</li>
                  {/* <li>Finland</li>
                  <li>France</li> */}

                  <li>Gabon</li>
                  <li>Gambia</li>
                  <li>Georgia</li>
                  <li>Ghana</li>
                  <li>Greenland</li>
                  <li>Grenada</li>
                  <li>Guatemala</li>
                  <li>Guinea</li>
                  <li>Guinea-Bissau</li>
                  <li>Guyana</li>
                  {/* <li>Germany</li>
                  <li>Greece</li> */}

                  <li>Honduras</li>
                  <li>Hong Kong</li>
                  {/* <li>Haiti</li>
                  <li>Hungary</li> */}

                  <li>Iceland</li>
                  <li>Indonesia</li>
                  <li>Israel</li>
                  {/* <li>India</li>
                  <li>Iran</li>
                  <li>Iraq</li>
                  <li>Ireland</li>
                  <li>Italy</li> */}

                  <li>Jamaica</li>
                  <li>Jordan</li>
                  {/* <li>Japan</li> */}

                  <li>Kazakhstan</li>
                  <li>Kiribati</li>
                  <li>Kosovo</li>
                  <li>Kuwait</li>
                  <li>Kyrgyzstan</li>
                  {/* <li>Kenya</li>
                  <li>Korea, North</li>
                  <li>Korea, South</li> */}

                  <li>Lebanon</li>
                  <li>Lesotho</li>
                  <li>Liberia</li>
                  {/* <li>Laos</li>
                  <li>Latvia</li>
                  <li>Libya</li>
                  <li>Liechtenstein</li>
                  <li>Lithuania</li>
                  <li>Luxembourg</li> */}

                  <li>Macau</li>
                  <li>Madagascar</li>
                  <li>Malawi</li>
                  <li>Malaysia</li>
                  <li>Maldives</li>
                  <li>Marshall Islands</li>
                  <li>Mauritania</li>
                  <li>Mauritius</li>
                  <li>Mayotte</li>
                  <li>Mexico</li>
                  <li>Micronesia</li>
                  <li>Mongolia</li>
                  <li>Montenegro</li>
                  <li>Morocco</li>
                  {/* <li>Mali</li>
                  <li>Malta</li>
                  <li>Moldova</li>
                  <li>Monaco</li>
                  <li>Mozambique</li>
                  <li>Myanmar (Burma)</li> */}

                  <li>North Macedonia</li>
                  <li>Nauru</li>
                  <li>Nepal</li>
                  <li>New Caledonia</li>
                  <li>Niger</li>
                  <li>Niue</li>
                  {/* <li>Namibia</li>
                  <li>Netherlands</li>
                  <li>New Zealand</li>
                  <li>Nicaragua</li>
                  <li>Nigeria</li>
                  <li>Norway</li> */}

                  <li>Oman</li>

                  <li>Palau</li>
                  <li>Papua New Guinea</li>
                  <li>Paraguay</li>
                  <li>Peru</li>
                  <li>Philippines</li>
                  <li>Pitcairn</li>
                  {/* <li>Pakistan</li>
                  <li>Panama</li>
                  <li>Poland</li>
                  <li>Portugal</li> */}

                  <li>Qatar</li>

                  <li>Rwanda</li>
                  {/* <li>Romania</li>
                  <li>Russia</li> */}

                  <li>Saint Helena, Ascension & Tristan Da Cunha</li>
                  <li>Sao Tome and Principe</li>
                  <li>Saudi Arabia</li>
                  <li>Saint Kitts and Nevis</li>
                  <li>Saint Lucia</li>
                  <li>Saint Vincent and the Grenadines</li>
                  <li>Samoa</li>
                  <li>Serbia</li>
                  <li>Seychelles</li>
                  <li>Sierra Leone</li>
                  <li>Solomon Islands</li>
                  <li>South Africa</li>
                  <li>Sri Lanka</li>
                  <li>Suriname</li>
                  <li>Switzerland</li>
                  {/* <li>San Marino</li>
                  <li>Senegal</li>
                  <li>Singapore</li>
                  <li>Slovakia</li>
                  <li>Slovenia</li>
                  <li>Somalia</li>
                  <li>South Sudan</li>
                  <li>Spain</li>
                  <li>Sudan</li>
                  <li>Sweden</li>
                  <li>Syria</li> */}

                  <li>Taiwan</li>
                  <li>Tajikistan</li>
                  <li>Thailand</li>
                  <li>Timor-Leste</li>
                  <li>Togo</li>
                  <li>Tokelau</li>
                  <li>Tonga</li>
                  <li>Tunisia</li>
                  <li>Turkmenistan</li>
                  <li>Tuvalu</li>
                  {/* <li>Tanzania</li>
                  <li>Trinidad and Tobago</li>
                  <li>Turkey</li> */}

                  <li>Ukraine</li>
                  <li>United Arab Emirates</li>
                  <li>Uruguay</li>
                  <li>Uzbekistan</li>
                  {/* <li>Uganda</li>
                  <li>United Kingdom</li>
                  <li>United States</li> */}

                  {/* <li>Vanuatu</li>
                  <li>Vatican City</li>
                  <li>Venezuela</li>
                  <li>Vietnam</li> */}

                  <li>Wallis & Futuna Island</li>
                  <li>Western Sahara</li>

                  {/* <li>Yemen</li> */}

                  <li>Zambia</li>
                  {/* <li>Zimbabwe</li> */}
                </ul>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </>
    );
  }
}

export default BeforeRulesRegulations;
